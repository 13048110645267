import { graphql } from 'gatsby';
import React, { useMemo, useCallback, useState } from 'react';
import remark from 'remark';
import remark2react from 'remark-react';
import { GatybyPageProps, IGatsbyQueryResultProps, IMedia, IGatsbyFile } from '~/types';
import Overlay from '../comps/overlay';
import './media-page.scss';
import { tryget } from '../utils';
import YouTube from 'react-youtube';
import classnames from 'classnames'

const markdownParser = remark().use(remark2react)
const parseMarkdown = (md: string) => markdownParser.processSync(md).contents

interface Props extends GatybyPageProps<IMedia> {}

interface QuerResult extends IGatsbyQueryResultProps<{
  allContentYaml : {medias: IMedia[]};
  video          : {publicURL: string};
  images         : Pick<IGatsbyFile, 'publicURL'|'base'>;
}> {}


// interface YoutubeFrameProps {
//   src: string; width: number; height: number;
//   className? :string;
// }
// const YoutubeFrame = (props: YoutubeFrameProps) => (
//   <iframe
//     width={props.width}
//     height={props.height}
//     src={props.src}
//     frameBorder="0"
//     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//     allowFullScreen
//     className={props.className}
//   />
// )


const MediaPage = (props: GatybyPageProps<{index: number}> & QuerResult) => {
  const media = props.data.allContentYaml.nodes[0].medias[props.pageContext.index]
  let url = ''

  const [isVideoPlaying, setVideoPlaying] = useState(false)

  const onPlay = useCallback(() => setVideoPlaying(true), [])
  const onPause = useCallback(() => setVideoPlaying(false), [])

  const videoDimensions = useMemo(() => {
    const width = media.videoWidth || 560
    const height = media.videoHeight || 315
    return {
      width,
      height,
      ratio: (height / width || 0),
    }
  }, [media])

  const videoElementStyle = useMemo (() => (
    { paddingBottom: `${videoDimensions.ratio * 100}%`}
  ), [videoDimensions])

  
  // contruct VideoElement:
  const VideoElement = useMemo( () => {
    let VideoElement: JSX.Element|undefined
    const isYoutube = media.video.startsWith('https://www.youtube')
    const isVimeo = !isYoutube && media.video.startsWith('https://player.vimeo')
    
    if (isYoutube) {
      const segments = media.video.split('/').filter(s => !!(s))
      const videoId = tryget(() => segments[segments.indexOf('embed') + 1], '')
      VideoElement =
        <YouTube
          videoId={videoId}
          className="media-page--video"
          opts={{host: "https://www.youtube-nocookie.com"} as any}
        />
    } else if (isVimeo) {
      let src = media.video
      if (src.indexOf('dnt=1') === -1) {
        src += src.indexOf('?') === -1 ? '?dnt=1' : '&dnt=1'
      }
      VideoElement =
        <iframe
          className="media-page--video"
          src={src}
          width={videoDimensions.width}
          height={videoDimensions.height}
          frameBorder="0"
          allow="autoplay; fullscreen" allowFullScreen
        />
    } else {
      try {
        url = props.data.video.nodes[0].publicURL
        const poster = tryget(() => props.data.images.nodes.find( image => image.base === media.poster)!.publicURL );
        VideoElement =
          <>
            <video
              src={url}
              controls={true}
              poster={poster}
              className="media-page--video"
              onPlaying={onPlay}
              onPause={onPause}
            />
            <div className="play-button"/>
          </>
      } catch (err) {}
    }
    return VideoElement;
  }, [media])

  return (
    <div className="media-page">
      <Overlay>
        <div className="media-page--content">      
          {media && <>
            <div className="media-page--header">
              <h1>{media.title}</h1>
            </div>
            <div
              className={classnames('media-page--vidcontainer', {playing: isVideoPlaying})}
              style={videoElementStyle}
            >
              {VideoElement}
            </div>
          </>}
        </div>
        { media && media.description && (
          <div className="media-page--description">
            {parseMarkdown(media.description)}
          </div>
        )}
      </Overlay>
    </div>
  )
} 


export const query = graphql`
  query($video: String!) {
    allContentYaml {
      nodes {
        medias { name title thumb video videoWidth videoHeight description }
      }
    }
    video:allFile(filter:{ base:{ eq: $video } }) {
      nodes {
        publicURL
      }
    }
    images:allFile(filter: {extension: {in: ["jpg", "png", "gif"]}}) {
      nodes { publicURL base }
    }
  }
`

export default MediaPage;