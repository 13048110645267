import React, { CSSProperties } from 'react'
import './overlay.scss'
import { Link } from 'gatsby';

interface Props {
  // if box should be scrollable fullHeight has to be true:
  fullHeight?: boolean
  children: any;
}

const Overlay = (props: Props) => {
  const boxStyle: CSSProperties = {}
  if (props.fullHeight) { boxStyle.height = '100%' }

  return (
    <>
      <Link to="/" className="overlay--bg" />
      <div className="overlay">
        <div className="overlay--container">
          <div className="overlay--box" style={boxStyle}>
            { props.children }
          </div>
        </div>
      </div>
    </>
  )
}

export default Overlay

